<template>
  <div class="headers" :style="{
    'background-color': bgColor
  }">
    <div class="back" @click="$router.back()">
      <icon-font type="icon-fanhui" />
    </div>
    <div class="title">{{title}}</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: String,
    bgColor: {
      type: String,
      default: '#ceb175'
    }
  },
  setup () {
    return {}
  }
})
</script>

<style lang="less" scoped>
.headers{
  display: flex;
  justify-content: center;
  position: relative;
  height: 0.87rem;
  align-items: center;
  font-size: 0.36rem;
  color: #fff;
  .back{
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
